// Scripts for Travel Plan Toolkit
var helpMode = false; // Can set with cookie

$(document).ready(function() {

    /* On load */

	// Javascript to enable link to tab - for sign-in/register page
	if (document.location.hash) {
		// var hashLink = document.location.hash.replace("_btn","");
		var hashLink = document.location.hash;
		var tabLink;
		if (hashLink) {
			if ($('.nav-pills a[href='+hashLink+']')) {
				tabLink = $('.nav-pills a[href='+hashLink+']');
			} else if ($('.nav-tabs a[href='+hashLink+']')) {
				tabLink = $('a[href='+hashLink+']');
			}
			if (!tabLink.parent('div').hasClass('disabled')) {
				// if not disabled
				tabLink.tab('show');
			}
		} 
	}

	// Change hash for page-reload
	$(".nav-pills a, .nav-tabs a").on("shown.bs.tab", function (e) {
    	e.preventDefault();
		// window.location.hash = e.target.hash + "_btn";
		window.location.hash = e.target.hash;
	});

    // Reset forms on reload (IE & FF)
    resetForms();

    // Set global vars (helpMode) to cookie values, if set
    checkCookies();

    // Hide help blocks unless in Help mode

    if (helpMode) {
        $(".help-mode").show();
        $(".on-btn").addClass("active").find("input").prop("checked", true);
        $("a[data-toggle='help']").addClass("disabled");
    } else {
        $(".off-btn").addClass("active").find("input").prop("checked", true);
    }

    // Add copyright date
    setCopyright();

    /* Add behaviours */

    // Remove on click behaviour for disabled nav links
    // Need to add again when removing 'disabled' attribute
    $(".nav li a[data-toggle]").each(function(){
        if($(this).parent('li').hasClass("disabled")){
            $(this).removeAttr("data-toggle");
        }
    });

    // Timepicker (24-hour clock)
    if ($('.ui-timepicker-input').length > 0) {
        $('.ui-timepicker-input').timepicker({
            'timeFormat': 'H:i',
            'step': 15
        });
    }

    $('#peak-hour').on('changeTime', function() {
        // add hour
        var selectedTime = $(this).val();

        setTimeString(selectedTime);
    });

    $('#peak-hour').on("blur", function() {
        var selectedTime = $(this).val();
        if (selectedTime == "" || selectedTime == "e.g. 00:00") {
            $('#peak-hour-plus').text("");
        }
    });

    // Show/hide help when clicking individual help buttons

    $("a[data-toggle='help']").click(function(e) {
        e.preventDefault();
        var thisHelp = $(this).attr("data-target");
        if (!$(this).hasClass("disabled")) {
            $(thisHelp).slideToggle();
        }
    });

    // Show/hide all help when toggling help on/off buttons

    $("#help-off, #help-on").change(function() {
        $("a[data-toggle='help']").toggleClass("disabled");
        if (helpMode) {
            helpMode = false;
            setCookie("helpMode", false, 365);
            $(".help-mode").slideUp();
        } else {
            helpMode = true;
            setCookie("helpMode", true, 365);
            $(".help-mode").slideDown();
        }
    });

    // Toggle flag on/off for Issues & Opportunities
    $(".flag-off, .flag-on").on("mousedown", function() {
        $(this).toggleClass("flag-on flag-off");
    });

	if ($("#district-selector").length > 0) {

		// Make image maps responsive (uses jquery.rwdImageMaps.min.js)
		$("#district-selector img[usemap]").rwdImageMaps();
	
		// Map area rollover - if no map area already selected
		$("#district-selector area[id]")
		  .mouseover(function() { 
			 var mapType = $(this).parents("#district-selector").attr("class");
			 var mapDistrict = $(this).attr("id").toLowerCase();
			 if ($("#DistrictsMap area.active").length < 1) {
				var newmap = "../images/districts/map-" + mapType + "-" + mapDistrict + ".png";
				$("#mapimage").attr("src", newmap);
			}
		  })
		  .mouseout(function() {
			  var mapType = $(this).parents("#district-selector").attr("class");
			  if ($("#DistrictsMap area.active").length < 1) {
				$("#mapimage").attr("src", "../images/districts/map-" + mapType + ".png");
			  }
		  });
			
		// Map functions - loads content from remote URL into right col
	
		$("#district-selector area, #district-selector a").on("click", function(e) {
			// Get current active
			var old = $("#district-selector .active");
			var next = $(this);
			var oldClass = old.attr("class") || "";
			var nextClass = $(this).attr("class") || "";
			var mapType = $(this).parents("#district-selector").attr("class");
			var mapDistrict = $(this).attr("id").toLowerCase();
			
			if (old.length > 0) {
				// Remove active class from old
				old.attr("class", oldClass.replace("active",""));
			}
			
			// Add active class to new
			next.attr("class", nextClass += " active");
			
			var dir = next.attr("data-directory");
			var district = next.attr("id");
			var url = dir + "/" + district.toLowerCase();
			
			$( "#districts-right" ).fadeOut(500, function() {
			
				$(this).load(url + " #district-info").fadeIn(500);
	
			});
	
			// Swap map image
			var newmap;
			
			if (district == "TfGM") {
			  newmap = "../images/districts/map-" + mapType + ".png";
			} else {
			  newmap = "../images/districts/map-" + mapType + "-" + mapDistrict + ".png";
			}
			$("#mapimage").attr("src", newmap);
			
		});
		
		// check if there's a district in the hash
		var hashLink = document.location.hash;
		  
		  switch (hashLink) {
		  
			case "#Bolton":
		      $("#Bolton").click();
			  break;
		  
			case "#Bury":
		      $("#Bury").click();
			  break;
		  
			case "#Manchester":
		      $("#Manchester").click();
			  break;
		  
			case "#Oldham":
		      $("#Oldham").click();
			  break;
		  
			case "#Rochdale":
		      $("#Rochdale").click();
			  break;
		  
			case "#Salford":
		      $("#Salford").click();
			  break;
		  
			case "#Stockport":
		      $("#Stockport").click();
			  break;
		  
			case "#Tameside":
		      $("#Tameside").click();
			  break;
		  
			case "#Trafford":
		      $("#Trafford").click();
			  break;
		  
			case "#Wigan":
		      $("#Wigan").click();
			  break;
			
			default:
			  if ($("#TfGM").length > 0) {
				  $("#TfGM").click();
			  } else if ($("#Manchester").length > 0) {
				$("#Manchester").click();
			  }
		  }
	}

    /* Archive search functions */

    // Submit archive search
    $( "#archiveSearch" ).submit(function( event ) {

        $('#searchingModal').modal({
            keyboard: false
        });

        event.preventDefault();

        // Timeout for testing with fake results
        window.setTimeout(function() {
            $("#searchTabs a[href='#results']").parents("li").removeClass("disabled").find("a").attr("data-toggle","tab");
            $("#searchTabs a[href='#results']").tab("show");
            $('#searchingModal').modal('hide');
        },2000);

    });

    $("#search-again").click(function() {
        $("#searchTabs a[href='#find']").click();
        $("#searchTabs a[href='#results']").removeAttr("data-toggle").parent("li").addClass("disabled");
    });

    /* My Travel Plans */

    // Tree view for Design Archive

    $(function () {
        $('.tree li:has(ul)').addClass('parent_li').find(' > span');
        $('.tree li.parent_li > span').on('click', function (e) {
            var children = $(this).parent('li.parent_li').find(' > ul > li');
            if (children.is(":visible")) {
                children.hide('fast');
                $(this).removeClass('open').find(' > i.fa-caret-down').addClass('fa-caret-right').removeClass('fa-caret-down');
                $(this).find(' > i.fa-folder-open').addClass('fa-folder').removeClass('fa-folder-open');
            } else {
                children.show('fast');
                $(this).addClass('open').find(' > i.fa-caret-right').addClass('fa-caret-down').removeClass('fa-caret-right');
                $(this).find(' > i.fa-folder').addClass('fa-folder-open').removeClass('fa-folder');
                // and hide others
                $(this).parent('li.parent_li').siblings().find('.open').click();
            }
            e.stopPropagation();
        });
    });

    /* Submit form on navigation link click */

    $('#tp-nav, #measures-links').find('a').on('click', function(e) {
        // Prevent default action
        e.preventDefault();
        submitAndNavigate($(this));
    });

    $('a.edit-site-location').on('click', function(e) {
        e.preventDefault();
        submitAndNavigate($(this));
    });

});

// Functions

function resetForms() {
    for (i = 0; i < document.forms.length; i++) {
        document.forms[i].reset();
    }
}

// Cookie functions

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toGMTString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1);
        if (c.indexOf(name) != -1) return c.substring(name.length, c.length);
    }
    return "";
}

function checkCookies() {
    var helpModeCookie = getCookie("helpMode");
    if (helpModeCookie == "true") {
        helpMode = true;
    } else {
        helpMode = false;
    }
}

// Copyright

function setCopyright() {
    // Add year to copyright statements.
    var d = new Date();
    var n = d.getFullYear();
    $('.addYear').append(n);
}

// Add zero padding to numbers in timepicker 24-hour format

function pad (str, max) {
    str = str.toString();
    return str.length < max ? pad("0" + str, max) : str;
}

// Submit form and navigate
function submitAndNavigate($link)
{
    var href = $link.attr('href');
    var $form = $('#tp-designer-panel').find('form');
    if ($form.length === 1)
    {
        var formData = $form.serialize();
        $.ajax({
            url: window.location.href,
            data: formData,
            method: 'POST',
            error: function(jqXHR, textStatus, errorThrown)
            {
                handleAjaxError(jqXHR);
            },
            success: function(data, textStatus, jqXHR) {
                window.location.href = href;
            }
        });
    }
    else
    {
        window.location.href = href;
    }
}

function handleAjaxError(jqXHR)
{
    var html = function (title, body, button) {
        return '<div id="modal" class="modal fade" tabindex="-1" role="dialog">' +
            '<div class="modal-dialog">' +
            '<div class="modal-content">' +
            '<div class="modal-header">' +
            '<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>' +
            '<h4 class="modal-title">' + title + '</h4>' +
            '</div>' +
            '<div class="modal-body">' +
            body +
            '</div>' +
            '<div class="modal-footer">' +
            button +
            '</div>' +
            '</div>' +
            '</div>' +
            '</div>';
    };

    var modal = '';

    switch (parseInt(jqXHR.status, 10))
    {
        case 401:
            modal = html('Session Timeout', '<p>Your session has timed out due to inactivity.</p><p>Please login to the application again to continue.</p>', '<a class="btn btn-primary" href="' + window.location.href + '"><i class="fa fa-refresh"></i> Re-authenticate</a>');
            break;
        default:
            modal = html('Unknown Error', '<p>An unknown error has occurred.</p><p>Please try refreshing your browser to resolve the issue.</p>', '<a class="btn btn-primary" href="' + window.location.href + '"><i class="fa fa-refresh"></i> Refresh</a>');
    }

    if ($('#modal').length === 0)
    {
        $('body').append(modal);
    }
    else {
        $('#modal').replaceWith(modal);
    }

    $('#modal').modal();
}